import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { getFlex, palette } from "../../styles/styles";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

const First = (props) => {
  const { className } = props;
  const { t } = useTranslation("autoHunt");
  const { language } = useI18next();
  return (
    <section className={className}>
      <h2>{t("firstTitle")}</h2>
      <p className="desc">{t("firstDesc")}</p>
      {language === "es" ? (
        <StaticImage
          className="firsImg"
          src="../../images/carousel/autoHuntEn.png"
          alt=""
        />
      ) : (
        <StaticImage
          className="firsImg"
          src="../../images/carousel/autoHunt.png"
          alt=""
        />
      )}
      <hr />
    </section>
  );
};

export default styled(First)`
  ${getFlex("column", "ct", "ct")};
  padding: 7rem 16% 0 16%;
  @media (max-width: 768px) {
    padding: 10%;
  }
  h2 {
    font-size: 4.8rem;
    text-align: center;
    max-width: 900px;
    width: 100%;
    font-weight: 400;
    margin-bottom: 3rem;
    @media (max-width: 600px) {
      font-size: 3.8rem;
      width: 100%;
    }
  }
  .desc {
    font-family: SofiaProLightAz, sans-serif;
    font-size: 2rem;
    line-height: 3.2rem;
    text-align: center;
    max-width: 850px;
    width: 100%;
    margin-bottom: 3rem;
    color: ${palette.grey};
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .firsImg {
    width: 90%;
    max-width: 1000px;
    margin-bottom: 7rem;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  hr {
    width: 100%;
    border: 0;
    border-bottom: 1px solid ${palette.greyChevron};
  }
`;
