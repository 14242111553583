import React from "react";
import styled from "styled-components";
import { linkRouge, palette} from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

const Final = (props) => {
  const { className } = props;
  const { t } = useTranslation("autoHunt");

  return (
    <section className={className}>
      <p className="red">{t("finalRed")}</p>
      <h2>{t("finalTitle")}</h2>
      <p>{t("finalDesc")}</p>
      <Link to="/headhunting" className="link">{t("learnMore")}</Link>
    </section>
  );
};

export default styled(Final)`
  padding: 8% 10%;
  height: fit-content;
  background-color: ${palette.bgGrey};
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  @media(max-width: 768px){
    padding: 10% 8%;
  }
  .red {
    color: ${palette.orange};
    font-size: 2.4rem;
    margin-bottom: 2rem;
    font-family: SofiaProSemiBoldAz, sans-serif;
    max-width: 100%;
  }
  h2 {
    font-size: 4.8rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  p {
    font-size: 2rem;
    font-family: SofiaProLightAz, sans-serif;
    text-align: center;
    line-height: 3.2rem;
    margin-bottom: 6rem;
    max-width: 800px;
    width: 100%;
    color: ${palette.grey};;
    @media(max-width: 768px){
        width: 90%;
    }
  }
  .link {
    ${linkRouge};
  }
`;
